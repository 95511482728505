import AppProvider from "@component/AppProvider";
import AppBar from "@feature/AppBar";
import Banner from "@feature/Banner";
import Catalogue from "@feature/Catalogue";

const App = () => {
    return (
        <AppProvider>
            <AppBar />
            <Banner />

            <Catalogue />
        </AppProvider>
    );
}

export default App;
