import defaultImage from '@style/images/1.jpg'
import { Box, Card, CardContent, CardMedia, Grid, Typography, styled } from '@component/UIComponents'
import { useURLParams } from '@hook/useURLParams'

const BoxStyled = styled(Box)(({ theme }) => ({
    border: '1px solid #dcd9d9',
    borderRadius: "10px",
    overflow: 'hidden',
    height: 280,
    backgroundColor: "#f3f3f3",
    [theme.breakpoints.down('sm')] : {
        height: 180,
    },
}))

const TypographyStyled = styled(Typography, { shouldForwardProp: (prop) => prop !== 'title' && prop !== 'price' })(
    ({ theme, title, price }) => ({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        ...(Boolean(title) ? {
            color: '#222529',
            fontSize: theme.typography.body1,
            fontWeight: theme.typography.fontWeightMedium,
            textAlign: 'center',
            [theme.breakpoints.down('sm')] : {
                fontSize: ".750rem",
                textAlign: 'left',
                fontWeight: theme.typography.fontWeightRegular,
            },
        } : {}),
        ...(Boolean(price) ? {
            fontSize: theme.typography.h6,
            fontWeight: theme.typography.fontWeightBold,
            color: '#222529',
            textAlign: 'center',
            [theme.breakpoints.down('sm')] : {
                fontSize: ".850rem",
            },
        } : {}),
    })
)

const CardStyled = styled(Card)(({ theme }) => ({
    cursor: 'pointer',
    [theme.breakpoints.down('sm')] : {
        height: 255,
    },
}))

const ProductCard = ({ image, _id, name, price }) => {
    const { addQuery } = useURLParams()
    return(
        <>
            <CardStyled variant="elevation" elevation={0} onClick={() => addQuery({ ref: _id })} >
                <BoxStyled>
                    <CardMedia component="img" image={Boolean(image) ? image : defaultImage} />
                </BoxStyled>
                <CardContent>
                    <TypographyStyled title component="div" >
                        {name}
                    </TypographyStyled>
                    <Grid container sx={{
                        '& div': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }
                    }} >
                        <Grid xs={2} ></Grid>
                        <Grid xs={8} >
                            <Box width={280} >
                                <TypographyStyled price component='div' >
                                    { price.toLocaleString('es-CO', {style: 'currency',currency: 'COP'}) }
                                </TypographyStyled>
                            </Box>
                        </Grid>
                        <Grid xs={2} >
                            {/* <Tooltip title="Añadir al carrito" arrow >
                                <IconButton size="small" color="success" >
                                    <AddShoppingCartRoundedIcon />
                                </IconButton>
                            </Tooltip> */}
                        </Grid>
                    </Grid>
                </CardContent>
            </CardStyled>
        </>
    )
}

export default ProductCard