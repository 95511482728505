import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "../App";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [],
    },
]);

const Router = () => {
    return(
        <>
            <RouterProvider router={router} />
        </>
    )
}

export default Router