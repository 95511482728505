import { Box, Button, Container, FormControl, Grid, InputAdornment, InputLabel, KeyboardArrowRightRoundedIcon, MenuItem, Pagination, SearchRoundedIcon, Select, SpeedDial, TextField, styled } from '@component/UIComponents'
import ProductCard from './components/ProductCard'
import { useGetProductsForUnikoCatalog } from './hooks/useCatalogue'
import React from 'react'
import { useURLParams } from '@hook/useURLParams'
import ProductsFilter from './components/ProductsFilter'
import PreviewProduct from '@feature/PreviewProduct'
import Img from '@component/Img'
import whatsapp from '@style/images/whatsapp.png'

const GridStyled = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')] : {
        display: 'none'
    }
}))

const Catalogue = () => {
    const { addQuery, getQuery } = useURLParams()
    const page = (getQuery("page")??0)
    const items = (getQuery("items")??0)
    const menuFilter = (getQuery("menu_filter") === 'open')

    const { list, totalPages, textMatch, setTextMatch } = useGetProductsForUnikoCatalog({ items, page })

    const productLis = list.map((item, index) => {
        return(
            <React.Fragment key={index} >
                <Grid lg={ menuFilter ? 4 : 3} md={ menuFilter ? 4 : 3} sm={6} xs={6} >
                    <ProductCard
                        _id={(item?._id??"")}
                        price={((item.prices??[])[0]?.sellPrice??0)}
                        image={(item?.image??"")}
                        name={(item?.name??"")}
                        // description={'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen.'}
                    />
                </Grid>
            </React.Fragment>
        )
    })
    const pagination = <Pagination boundaryCount={0} siblingCount={0} color='primary' count={totalPages} shape='rounded' page={Number(page)} onChange={(_, value) => addQuery({ page: value })} />
    const resultsPerPage = <FormControl size='small' sx={{ width: 100 }} >
        <InputLabel> Mostrar </InputLabel>
        <Select label="Mostrar" value={items} onChange={({ target }) => addQuery({items: (target?.value??"")})} >
            <MenuItem value={25} >25</MenuItem>
            <MenuItem value={50} >50</MenuItem>
            <MenuItem value={100} >100</MenuItem>
        </Select>
    </FormControl>

    React.useEffect(() => {
        if( !Boolean(page) || !Boolean(items) ){
            addQuery({ page: 1, items: 25 })
        }
    }, [page, items, addQuery])
    return(
        <>
            <Container sx={{ padding: 2 }} >
                <Box my={1} >
                    <TextField onChange={({target}) => setTextMatch(target.value)} value={textMatch} InputProps={{
                        startAdornment: <InputAdornment position='start' > <SearchRoundedIcon /> </InputAdornment>
                    }} fullWidth placeholder="Buscar..." variant='standard' />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', gap: 2, position: 'relative', justifyContent: Boolean(menuFilter) ? 'space-between' : 'center'}} >
                    <Box display={menuFilter ? 'block' : 'none'} width={menuFilter ? 270 : 0} overflow={'hidden'} sx={{
                        transition: (theme) => theme.transitions.create('all', { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen, }),
                    }} >
                        <ProductsFilter />
                    </Box>
                    <Box sx={{ width: menuFilter ? 'calc(100% - 300px)' : '100%', transition: (theme) => theme.transitions.create('all', { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen, }) }} overflow={'hidden'} >
                        <Grid container spacing={2} sx={{ mt: 1 }} >
                            <GridStyled xs={12} >
                                <Button endIcon={<KeyboardArrowRightRoundedIcon />} variant='outlined' onClick={ () => addQuery({ menu_filter: 'open' }) } >
                                    Filtros
                                </Button>
                            </GridStyled>
                            {productLis}
                            <Grid xs={12} sx={{ display: 'flex', justifyContent: 'end' }} >
                                {resultsPerPage}
                                {pagination}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
            <PreviewProduct list={list} />
            <SpeedDial onClick={() => window.open("https://wa.me/573215164253", '_blank')} ariaLabel="SpeedDial basic example" sx={{ position: 'fixed', bottom: 16, right: 16, background: 'none' }} icon={<Img src={whatsapp} alt={whatsapp} />} />
        </>
    )
}

export default Catalogue