import { LocalizationProvider, AdapterDayjs, CssBaseline, Box } from '@component/UIComponents'
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "dayjs/locale/es";
import "dayjs/locale/en";
import { store } from '@util/store'
import { Provider } from 'react-redux';

const AppProvider = ({ children }) => {
    return(
        <>
            <Provider store={store} >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" >
                    <CssBaseline />
                    <Box width={'100%'} height={'100%'} >
                        {children}
                    </Box>
                </LocalizationProvider>
            </Provider>
        </>
    )
}

export default AppProvider