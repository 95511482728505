import { api } from "../api"

const API = api.injectEndpoints({
    endpoints: (build) => ({
        // authenticateUser: build.mutation({
        //     query(body){
        //         return { ...mutationConfig, url: '/authenticate/user', body}
        //     },
        // }),
        getProductsForUnikoCatalog: build.query({
            query: ({ resultsPerPage, pageNumber, category }) => ({
                url: `/get/products/for/uniko/catalog`,
                params: { resultsPerPage, pageNumber, category }
            }),
        }),
        getProductCategoriesForEcommerce: build.query({
            query: () => ({
                url: `/get/product/categories/for/ecommerce`,
            }),
        }),
        // updateUser: build.mutation({
        //     query(body){
        //         return { ...mutationConfig, url: '/update/user', body}
        //     },
        //     invalidatesTags: ['Users']
        // }),
        // deleteUser: build.mutation({
        //     query(body){
        //         return { ...mutationConfig, url: '/delete/user', body}
        //     },
        //     invalidatesTags: ['Users']
        // }),
        // getUsers: build.query({
        //     query: () => ({
        //         url: `/get/users`,
        //     }),
        //     providesTags: ['Users']
        // }),
        // getUser: build.query({
        //     query: ({ userId }) => ({
        //         url: `/get/user`,
        //         params: { userId }
        //     }),
        //     providesTags: ['Users']
        // }),
    }),
    overrideExisting: false,
})

export const {
    useGetProductsForUnikoCatalogQuery,
    useGetProductCategoriesForEcommerceQuery,
} = API