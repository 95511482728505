import Img from '@component/Img'
import { AppBar as AppBarMUI, Box, Button, KeyboardArrowRightRoundedIcon, Stack, Toolbar, styled } from '@component/UIComponents'
import { useURLParams } from '@hook/useURLParams'
// import ShoppingCart from '@feature/ShoppingCart'
import unikoLg from '@style/images/uniko.jpg'
import { useNavigate } from 'react-router-dom'

const BoxStyled = styled(Box)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('sm')] : {
        display: 'flex'
    }
}))

const AppBar = () => {
    const navigate = useNavigate()
    const { addQuery } = useURLParams()
    return (
        <>
            <AppBarMUI position="fixed" elevation={0} color='inherit' >
                <Toolbar>
                    <Stack width={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'} pt={2} >
                        <Box display={'flex'} justifyContent={'start'} alignItems={'center'} >
                            <Box maxWidth={130} onClick={() => navigate("/")} sx={{ cursor: 'pointer' }} >
                                <Img src={unikoLg} alt={unikoLg} />
                            </Box>
                        </Box>
                        <Box display={'flex'} justifyContent={'end'} alignItems={'center'} >
                            <BoxStyled>
                                <Button endIcon={<KeyboardArrowRightRoundedIcon />} variant='outlined' onClick={ () => addQuery({ menu_filter: 'open' }) } >
                                    Filtros
                                </Button>
                            </BoxStyled>
                            {/* <ShoppingCart /> */}
                        </Box>
                    </Stack>
                </Toolbar>
            </AppBarMUI>
            <Toolbar />
        </>
    )
}

export default AppBar