import { useURLParams } from "@hook/useURLParams"
import { useGetProductCategoriesForEcommerceQuery, useGetProductsForUnikoCatalogQuery } from "@query/requests/Catalogue"
import React from "react"

export const useGetProductsForUnikoCatalog = ({ page=1, items=25 }) => {
    const  [ textMatch, setTextMatch ] = React.useState("")
    const { getQuery } = useURLParams()
    const category  = getQuery("category")??""

    const { data, isLoading, isFetching } = useGetProductsForUnikoCatalogQuery({ resultsPerPage: items, pageNumber: page, category })
    let list = React.useMemo(() => (data?.payload?.list??[]), [data]) 
    let totalResults = (data?.payload?.totalResults??0)
    let totalPages = (data?.payload?.totalPages??0)
    list = list.map((n) => {
        let item = { ...n }
        if (Boolean(item?.image)) {
            item.image = new Blob([Uint8Array.from(atob(item.image), (c) => c.charCodeAt(0))], { type: `image/${(item?.filetype??"")}` })
            item.image = URL.createObjectURL(item.image)
        }
        return item
    })
    const resultRows = React.useMemo(() => {
        let n = [...list]
        n = n.filter((item) => (item?.name??"").toUpperCase().includes(textMatch.toUpperCase()))
        return n
    }, [list, textMatch])
    list = resultRows
    return {
        list,
        isFetching,
        isLoading,
        totalResults,
        totalPages,
        setTextMatch,
        textMatch
    }
}

export const useGetProductCategoriesForEcommerce = () => {
    const { data, isLoading, isFetching } = useGetProductCategoriesForEcommerceQuery()
    const list = (data?.payload??[])
    return {
        list,
        totalResults: list.length,
        isLoading,
        isFetching,
    }
}