import React from 'react'
import { Box, CloseRoundedIcon, IconButton, KeyboardArrowDownRoundedIcon, KeyboardArrowUpRoundedIcon, TreeItem, TreeView, Typography } from '@component/UIComponents'
import { useGetProductCategoriesForEcommerce } from '../hooks/useCatalogue'
import { useURLParams } from '@hook/useURLParams'

const ProductsFilter = () => {
    const [openFilters, setOpenFilters] = React.useState([])
    const { addQuery, delQueries, getQuery } = useURLParams()
    const { list, totalResults } = useGetProductCategoriesForEcommerce()
    const openMenuFilter = (getQuery("menu_filter") === "open")
    const TreeItemParent = ({ children, title, helperText }) => <TreeItem nodeId='1' label={<>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
            <Typography fontWeight={'500'} fontSize={'1.1rem'} > {title} </Typography>
            <Typography fontSize={'.875rem'} fontWeight={'400'} color={'grey'} > {helperText} </Typography>
        </Box>
    </>}>
        {children}
    </TreeItem>
    
    React.useEffect(() => {
        if( openMenuFilter ){
            setOpenFilters(["1"])
        }
    }, [openMenuFilter])
    return(
        <>
            
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                <Typography variant='body1' textTransform={'uppercase'} fontWeight={'bold'} sx={{ color: (theme) => theme.palette.grey[700]}} > Filtros: </Typography>
                <IconButton size='small' color='error' onClick={() => delQueries(['menu_filter'])} >
                    <CloseRoundedIcon />
                </IconButton>
            </Box>

            <TreeView expanded={openFilters} defaultCollapseIcon={<KeyboardArrowUpRoundedIcon />} defaultExpandIcon={<KeyboardArrowDownRoundedIcon />} >
                <TreeItemParent title={'Categorías'} helperText={`(${totalResults})`} >
                    { list.map((item, index) => {
                        return(
                            <React.Fragment key={index} >
                                <TreeItem
                                    onClick={() => addQuery({ category: (item?.name??""), menu_filter: "close" })}
                                    nodeId={String(index+2)}
                                    label={<>
                                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                            <Typography variant='body2' >{(item?.name??"")}</Typography>
                                            <Typography variant='body2' > { `(${(item?.productsResults??0)})` } </Typography>
                                        </Box>
                                    </>}
                                />
                            </React.Fragment>
                        )
                    }) }
                </TreeItemParent>
            </TreeView>
        </>
    )
}

export default ProductsFilter