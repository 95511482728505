import Img from "@component/Img"
import { Box, Grid, styled } from "@component/UIComponents"
import banner from '@style/images/7.jpeg'

const BannerContainer = styled(Box)(({ theme }) => ({
    height: 500,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')] : {
        height: 200,
    }
}))

const Banner = () => {
    return(
        <>
            <Grid container sx={{ mt: 2 }} >
                <Grid xs={12} >
                    <BannerContainer height={500} overflow={'hidden'} >
                        <Img style={{ objectFit: "cover", height: "100%" }} src={banner} alt={banner} />
                    </BannerContainer>
                </Grid>
            </Grid>
        </>
    )
}

export default Banner