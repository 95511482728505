import { useSearchParams } from "react-router-dom";

export const useURLParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const addQuery = (queries) => {
        setSearchParams(prevSearchParams => {
            const updatedSearchParams = new URLSearchParams(prevSearchParams);
            Object.entries(queries).forEach(([name, value]) => {
                updatedSearchParams.set(name, value);
            });
            return Object.fromEntries(updatedSearchParams.entries());
        });
    }
    const delQueries = (names) => {
        names.map((name) => searchParams.delete(name))
        setSearchParams(searchParams)
    }
    const getQuery = (name) => searchParams.get(name)

    return {
        addQuery,
        delQueries,
        getQuery,
    }
}