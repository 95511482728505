import Img from '@component/Img'
import { Box, Dialog, DialogContent, Typography } from '@component/UIComponents'
import { useURLParams } from '@hook/useURLParams'

const PreviewProduct = ({ list=[] }) => {
    const { getQuery, delQueries } = useURLParams()
    const productId = getQuery("ref")
    const result = list.find((n) => (n?._id??"")===productId )

    return(
        <>
            <Dialog open={Boolean(result)} onClose={() => delQueries(['ref'])} fullWidth >
                <DialogContent>
                    <Box width={'100%'} height={400} display={'flex'} justifyContent={'center'} >
                        <Img src={(result?.image??"")} style={{ objectFit: 'cover' }} />
                    </Box>
                    <Box>
                        <Typography fontSize={'2rem'} fontWeight={'bold'} textAlign={'center'} >
                            { (result?.prices[0]?.sellPrice??0).toLocaleString('es-CO', {style: 'currency',currency: 'COP'}) }
                        </Typography>
                        <Typography fontSize={'1.3rem'} fontWeight={'bold'} >{ (result?.name??"") }</Typography>
                        <Typography fontSize={'.875rem'} fontWeight={'400'} lineHeight={1.2} color={'grey'} sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                        }} >
                            Para pedido escríbenos al <a href='https://wa.me/573215164253' target='_blank' rel='noreferrer' > +57 321 5164253 </a>
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default PreviewProduct