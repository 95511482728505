import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tags } from './tags';

export const domain = `https://api.facill.net`;
// export const domain = `http://localhost:3006`;
const prefix = `/store`;
export const endpoint = `${domain}${prefix}`;

export const mutationConfig = {
    url: '',
    method: 'POST',
    body: {},
};

export const api = createApi({
    reducerPath: 'Api',
    baseQuery: fetchBaseQuery({
        baseUrl: endpoint,
        timeout: 12000,
    }),
    tagTypes: [...tags],
    endpoints: () => ({}),
});